import * as React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import Layout from "../../components/Layout";
import { Meta } from "../../components/Meta";
import { theme } from "../../components/theme";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_INDEX_NAME,
  ALGOLIA_SEARCH_KEY,
} from "../../config/constans";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  RefinementList,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom";
import { Helmet } from "react-helmet";

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

interface SearchProps {}

const Search: React.FC<SearchProps> = () => {
  const Hit = (data: any) => {
    const hit = data.hit;
    return (
      <ResultsContent onClick={() => navigate(`${hit.url}`)}>
        <Badge>{hit.contentType}</Badge>
        <img alt={"searchImage"} src={`${hit.imageURL}?w=300`} />
        <HighlightTitle hit={hit} attribute="title" />
        <SnippetBody hit={hit} attribute="body" nonHighlightedTagName="span" />
      </ResultsContent>
    );
  };

  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>{`mogFlowts - Search`}</title>
      </Helmet>

      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css"
          integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8="
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/algolia-min.css"
          integrity="sha256-HB49n/BZjuqiCtQQf49OdZn63XuKFaxcIHWf0HNKte8="
          crossOrigin="anonymous"
        />
      </Helmet>

      <InstantSearch indexName={ALGOLIA_INDEX_NAME} searchClient={searchClient}>
        <Container>
          <SearchContainer>
            <SearchBox searchAsYouType={false} />
            <Category attribute="contentType" />
          </SearchContainer>
          <ResultsContainer>
            <Hits hitComponent={Hit} />
          </ResultsContainer>
          <FooterContainer>
            <Pagination />
            <PoweredBy />
          </FooterContainer>
        </Container>
      </InstantSearch>
    </Layout>
  );
};

export default Search;

const Container = styled.div`
  margin: 0 auto;
  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.main};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const SearchContainer = styled.div`
  padding: 15px;
  width: 100%;
`;

const ResultsContainer = styled.div`
  ul {
    margin: 0;
    padding: 0 15px;

    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  li {
    margin: 0;
    width: 100%;

    border-radius: 5px;
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    ul {
      margin: 0;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }

    li {
      margin: 0;
      width: 100%;

      border-radius: 5px;
    }
  }
`;

const FooterContainer = styled.div`
  padding: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > :nth-child(1) {
    margin-bottom: 10px;
  }
`;

const Category = styled(RefinementList)`
  padding: 10px 0;
`;

const ResultsContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

const Badge = styled.div`
  margin-bottom: 10px;
  padding: 0.5rem;

  width: 100px;

  color: ${theme.colors.grey40};
  text-align: center;

  background-color: ${theme.secondaryColors.green10};
  opacity: 0.5;
  border-radius: 24px;
`;

const HighlightTitle = styled(Highlight)`
  margin-bottom: 10px;

  font-weight: 600;
`;

const SnippetBody = styled(Snippet)``;
